<template>
  <div id="photobooth">
    
    <audio v-if="musicRecording" id="musicRecording" ref="musicRecording" :src="musicRecording" preload="auto" loop crossorigin="anonymous" />

    <div class="camera">
      <!-- Video object -->
      <div class="videoContainer" :class="{ 'recording': recording }">
        
        <qr-box 
          v-if="options.qrType === 'default'"
          :size="ctaState == 1 ? 'large' : 'small'"
          :position="ctaState == 1 ? 'center' : 'left'"
          title="Escaneá el QR, diseñá la<br>escena y grabá tu video" 
          subtitle="¡Luz, cámara, acción!" 
          :hidden="hideQr" 
          :darkmode="true"/>
        
         <video v-else-if="options.qrType === 'video'" class="qrOverlay" :class="{'hidden': gameSubState != SUBSTATES.cta}" :src="options.qrVideo.url" autoplay playsinline muted loop/>

         <img v-else-if="options.qrType === 'image'" class="qrOverlay" :class="{'hidden': gameSubState != SUBSTATES.cta}" :src="options.qrVideo.url"/>
        

        <canvas id="canvas" ref="photoboothCanvas"></canvas>
        <!-- <div id="blurBackground" :class="{ 'active': blurActive }"></div> -->

        <video id="photoboothVideo" :class="{ mirror: mirrorImage }" ref="photoboothVideo" autoplay>Video stream not
          available.</video>

          
          <div id="countdown" v-if="gameSubState == SUBSTATES.startingCapture">{{ countdown.number }}</div>
          
          <template v-if="stickers.enabled">
            <div v-for="sticker in stickers.list" :key="sticker.id">
              <video v-if="sticker.mediaType === 'video'" :src="sticker.video.url" preload="auto" playsinline
              webkit-playsinline muted autoplay loop crossorigin="anonymous" :ref="'sticker' + sticker.id"
              class="hiddenVideo" />
              <img v-else-if="sticker.mediaType === 'image'" :src="sticker.image.url" :ref="'sticker' + sticker.id" 
              class="hiddenImage" />
            </div>
          </template>
          
          
          
          <!-- Picture Frame -->
          <template v-if="pictureFrame" >
            <img style="visibility: hidden;" :src="pictureFrame.url" ref="pictureFrame"/>
          </template>
          
          <!-- Video Frame -->
          <video v-if="options.videoFrame" :src="options.videoFrame.url" id="videoFrame" ref="videoFrame" 
            :style="`filter: blur(${blur.current}px)`"
            preload="auto" playsinline webkit-playsinline muted autoplay loop crossorigin="anonymous" class="hiddenVideo"/>

        <template v-if="usersCapturing.length > 0">
          <div id="activePlayer">
            <!-- <div id="recIndicator">
              <div id="recIcon" v-if="userCapturing != null"></div> {{ userCapturing?.username }} está grabando
            </div> -->
            <PlayerAvatar :limitSize="false" :player="usersCapturing[0]" :scale="3" :showUsername="true" :horizontal="true" />
          </div>
          <!-- <b-progress id="recordingProgress" :value="recordingTimePercent" size="large"></b-progress> -->
        </template>

        <div v-show="recording" id="videoRecordingIndicator">
          <d-icon icon="FaVideo" size="is-medium"/>
          
          <span class="time">{{ recordingTimeLeft }}</span>
        </div>

        <!-- Captured Images preview -->
        <template v-if="gameSubState == SUBSTATES.sharing">
          <div id="capturedImage" v-if="showCapturedImages">
            <div id="imagesContainer">
              <div v-for="capture in capturedImages" :key="capture.id">
                <img :src="capture.url" />
              </div>
            </div>
          </div>

          <!-- Captured Video preview -->
          <div id="capturedVideo" v-if="showCapturedVideo">
            <video ref="capturedVideoPreview" :src="capturedVideoSrc" muted autoplay loop></video>
            <div id="capturedVideoCta">
              <d-icon icon="FaCircleInfo" size="is-medium" style="color:#008532"/>
              <span>Compartilo, guardalo o volvé a grabarlo</span>
            </div>
          </div>
        </template>

      </div>
      <div id="videoOptions" v-if="devices?.length > 0 && $store.state.space.showDebug">
        <!-- source selector -->
        <b-dropdown position="is-top-right" v-model="currentDeviceId" aria-role="list">
          <template #trigger="{ active }">
            <b-button :label="currentDevice?.label" :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item v-for="device in devices.filter(x => x.deviceId != currentDeviceId)" :value="device?.deviceId"
            :key="device?.deviceId">{{ device?.label }}</b-dropdown-item>
        </b-dropdown>
        <!-- Capture -->
        <b-button @click="CapturePhotoWithCountdown()">Capture w/countdown</b-button>
        <b-button @click="CapturePhoto()">Capture Photo</b-button>
        <b-button @click="CaptureVideo()">Capture Video</b-button>
      </div>

    </div>

    <div id="flash" v-show="showFlash"></div>
    
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import qrBox from '@/components/screens/qrBox.vue';
import PlayerAvatar from '@/components/PlayerAvatar.vue';
import VideoConversionQueue from '@/components/screens/VideoConversionQueue.js';

const utils = require("@/components/utils.js");

export default {
  components: {
    qrBox,
    PlayerAvatar,
  },
  props: {
    canvasWidth: {
      type: Number,
      required: false,
      default: 1920,
    },
    canvasHeight: {
      type: Number,
      required: false,
      default: 1080,
    },
    musicRecording:{
      type: String,
      required: false,
    },
    musicCreation:{
      type: String,
      required: false,
    },
    pictureFrame: {
      type: Object,
      required: false,
    },
    stickers: {
      type: Object,
      required: false,
    },
    captureMode: {
      type: String,
      required: false,
      default: "photo", // photo, video
    },
    cantDisparosPorToma: {
      type: Number,
      required: false,
      default: 1,
    },
    delayEntreDisparos: {
      type: Number,
      required: false,
      default: 1,
    },
    clipDuration: {
      type: Number,
      required: false,
      default: 5,
    },
    clipFps: {
      type: Number,
      required: false,
      default: 30,
    }
  },
  data() {
    return {
      SUBSTATES: Object.freeze({
        cta: 0,
        creating: 1,
        startingCapture: 2,
        capturing: 3,
        sharing: 4,
      }),
      gameSubState: 0,
      
      ctaState: 1, // 0: Visible + QR chico + personajes se mueven 1: Blur + QR grande - 10s,
      ctaTimer:{
        freqMoverPjs: 10, // En segundos cada cuanto se mueven de lugar los personajes
        elapsed: 0,
        timePersonajes: 20,
        timeBlur: 10,
      },
      activeStickers: [],
      
      CaptureTimeout: null,
      mediaRecorder: null,
      canvasRecorder: null,
      showCapturedVideo: false,
      showCapturedVideoTimeout: null,
      capturedVideoSrc: null,
      capturedVideoId: null,
      recording: false,
      usersCapturing: [],
      recordingTimePercent: 0,
      recordingTimeLeft: '00:00',

      webmBlob: null,
      mp4Blob: null,

      showFlash: false,
      currDisparo: 0,
      mirrorImage: true,
      capturedImages: [],
      showCapturedImages: false,
      countdown: {
        number: 3,
        timeout: null,
        timestampStarted: null,
      },
      canvasContext: null,
      devices: null,
      currentDeviceId: null,
      webcamStarted: false,
      constraints: {
        video: {
          advanced: [
            { width: { exact: 2560 } },
            { width: { exact: 1920 } },
            { width: { exact: 1280 } },
            { width: { exact: 1024 } },
            { width: { exact: 960 } },
            { width: { exact: 720 } },
            { width: { exact: 640 } },
            { width: { exact: 320 } }
          ]
        }
      },
      blur:{
        max: 20,
        current: 0,
      },
      garbageCounter: 0
    };
  },
  sockets: {
    SaveVideo(data) {
      this.SaveVideo()
    },
    CaptureFinish() {
      this.$socket.client.emit("kickPlayersToMenu");
      this.showCapturedImages = false;
    },
    async TakePhoto(data) {
      this.showCapturedImages = false;
      await this.StartCountDown();
      this.$socket.client.emit("roomPlayersMessage", { type: "captureStarted" })
      this.CapturePhoto()
      // userLeader = this.$store.state.space.players.find(x => x.dbid == data.userid)
      // this.usersCapturing = this.$store.state.space.players.filter(x => x.dbid == data.userid)
    },
    async TakeVideo(data) {
      this.$sfxStop("musicCreation")
			this.SetScreenPermissionToRestart(false);
      await this.StartCountDown();
      this.$socket.client.emit("roomPlayersMessage", { type: "captureStarted" })
      // this.$sfxPlay("musicRecording")
      console.log("*** ", this.$refs)
      this.$refs.musicRecording.currentTime = 0
      this.$refs.musicRecording.play()
      this.CaptureVideo()
      // userLeader = this.$store.state.space.players.find(x => x.dbid == data.userid)
      // this.usersCapturing = this.$store.state.space.players.filter(x => x.dbid == data.userid)
    },
    async ReshootPhoto(data) {
      // User requested to reshoot a photo
      // Voy a buscar la foto por ID y la elimino
      console.log("ReshootPhoto", data)
      this.$store.commit("StartLoading")
      // Array de urls para eliminar
      const urls = data.ids.map(id => process.env.VUE_APP_API_BASE + "/api/userimages/" + id)
      // Array de promesas de axios
      const deletePromises = urls.map(url => axios.delete(url))
      // Espero a que se eliminen todas las fotos
      const res = await Promise.all(deletePromises)
      console.log("res", res)
      this.capturedImages = []
      this.$store.commit("StopLoading")
      // Ahora vuelvo a capturar
      this.CapturePhotoWithCountdown()
    },
    async ReshootVideo() {
      // User requested to reshoot a video
      // Voy a buscar el video por ID y lo elimino
      console.log("Erasing Video")
      // Cancelo el timeout de mostrar el video
      window.clearTimeout(this.showCapturedVideoTimeout)
      // Array de urls para eliminar
      // const url = process.env.VUE_APP_API_BASE + "/api/uservideos/" + this.capturedVideoId
      // // Array de promesas de axios
      // const res = await axios.delete(url)
      // console.log("res", res)
      this.gameSubState = this.SUBSTATES.creating
      this.webmBlob = null
    },
    StickerToggled(data) {
      // console.log("StickerToggled", data)
      try {
        let sticker = this.stickers.list.find(x => x.id == data.id)
        sticker.enabled = data.state
        sticker.x = data.x
        sticker.y = data.y
        sticker.animx = data.x
        sticker.animy = data.y
      } catch (e) {
        console.error(e)
      }
    },
    StickerMoved(data) {
      try {
        let sticker = this.stickers.list.find(x => x.id == data.id)
        sticker.x = data.x
        sticker.y = data.y
      } catch (e) {
        console.error(e)
      }
    },
  },
  computed: {
    options() {
      return this.$store.getters["space/config"].experience.component[0];
    },
    hideQr() {
      return this.gameSubState != this.SUBSTATES.cta
    },
    blurActive() {
      if(this.ctaState == 0){
        return false
      }
      return this.gameSubState == this.SUBSTATES.cta || this.gameSubState == this.SUBSTATES.sharing
    },
    currentDevice() {
      return this.devices?.find(x => x.deviceId == this.currentDeviceId)
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue && !x.finishedPlaying)
    },
    serverState() {
      return this.$store.state.space.serverState;
    },
    videoFeedRotation(){
      let angles = this.options.rotateVideoFeed == 'none' ? 0 : this.options.rotateVideoFeed
      return parseFloat(angles);
    }
  },
  methods: {
    MoveActiveStickers(){
      // Muevo los stickers activos
      this.stickers.list.forEach((sticker, i) => { 
        if(sticker.enabled){
          setTimeout(() => {
            sticker.x = Math.random();
            sticker.y = Math.random();
          }, i * 10)
        }
      })
      this.UpdateStickerPostion()
    },
    ChangeActiveStickers(){
      // Apago todos
      this.options.stickers.list.forEach(sticker => { 
        sticker.enabled = false
      })
      // Elijo 2 random
      const randomStickers = utils.pickRandom(this.options.stickers.list, 2)
      // Los enciendo y doy posicion random
      randomStickers.forEach(sticker => { 
        sticker.enabled = true
        sticker.x = Math.random()
        sticker.y = Math.random()
      })
      this.UpdateStickerPostion()
    },
    UpdateStickerPostion(){
      this.$socket.client.emit("roomPlayersMessage", { 
        type: "stickersPosition", 
        stickers: this.options.stickers.list.map(x => ({id: x.id, x: x.x, y: x.y, enabled: x.enabled})),
        saveState: true, // Lo guarda en servidor para que cuando se conecte un nuevo jugador sepa como estan los stickers
      })

    },
    StickerPositionRemap(x,y){
      // Ingresa un x,y entre 0 y 1 que representa el cuadrado donde se ubican los stickers
      // Tenemos que mapearlo adentro de un trapecio definido por:
      // options.stickerLimitY es el alto maximo
      // options.stickerTopXScale es el ancho maximo arriba de todo
      // La base del trapecio es siempre 1
      let _y = utils.map(y, 0, 1, this.options.stickers.stickerLimitY, 1)
      // let _xmultiplier = utils.map(_y, 0, this.options.stickers.stickerLimitY, 1, this.options.stickers.stickerTopXScale)
      let topSidesOffset = (1 - this.options.stickers.stickerTopXScale) / 2
      let xTopTarget = utils.map(x, 0, 1, topSidesOffset, Math.abs(topSidesOffset) + this.options.stickers.stickerTopXScale)

      let bottomSidesOffset = (1 - this.options.stickers.stickerBottomXScale) / 2
      let xBottomTarget = utils.map(x, 0, 1, bottomSidesOffset, Math.abs(bottomSidesOffset) + this.options.stickers.stickerBottomXScale)
      let _x = utils.lerp(xTopTarget, xBottomTarget, y) // hago un lerp entre el x real y el calculado, segun la posicion en y
      // console.log("orig", x, y, "remap", _x, _y)
      return {x: _x, y: _y}
    },
    SaveVideo(){
      if(this.gameSubState != this.SUBSTATES.sharing){ return } // Solo aplica si todavia estoy en sharing
      clearTimeout(this.showCapturedVideoTimeout)
      this.$socket.client.emit("kickPlayersToMenu");
      VideoConversionQueue.AddVideo(
        this.webmBlob, 
        this.clipDuration,
        this.usersCapturing,
        this.$store.getters['space/config'].space, 
        this.$store.getters['space/config'].experience,
        this.handleVideoUpdate
      )
      this.garbageCounter++
      this.showCapturedVideo = false;
      this.gameSubState = this.SUBSTATES.cta;
    },
    SetScreenPermissionToRestart(allowed) {
      console.log("💫 Sent screen permission to restart", allowed)
      this.$socket.client.emit("setScreenPermissionToRestart", {allowed, spaceSlug: this.$store.state.space.spaceSlug});
    },
    async handleVideoUpdate(statusUpdate) {
        console.log("VideoConversionQueue status:", statusUpdate.filename, statusUpdate.status, " - garbageCounter:", this.garbageCounter);
        
 
        // Solo reinicia SOLO sí ya se convirtieron màs de 5 videos, no hay hay vidoes ne fila y estamos en CTA
        if(this.garbageCounter < 5) return
        if(statusUpdate.queueLength) return
        if(this.gameSubState !== this.SUBSTATES.cta) return;
        location.reload()
    },
    draw() {
      
      // Blur up and down
      if(this.blurActive){
        this.blur.current = utils.lerp(this.blur.current, this.blur.max, 0.1)
      }else{
        this.blur.current = utils.lerp(this.blur.current, 0, 0.1)
      }

      let ctx = this.canvasContext
      
      let canvas = this.$refs.photoboothCanvas
      if (!canvas) { requestAnimationFrame(this.draw.bind(this)); }
      
      let video = this.$refs.photoboothVideo
      ctx.clearRect(0, 0, canvas?.width, canvas?.height);

      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;

      let videoRotated = this.videoFeedRotation == 90 || this.videoFeedRotation == 270
      let videoWidth = videoRotated ? video.videoHeight : video.videoWidth
      let videoHeight = videoRotated ? video.videoWidth : video.videoHeight
      

      let videoAspectRatio = videoWidth / videoHeight;
      
      let canvasAspectRatio = canvas.width / canvas.height;
      let renderableWidth, renderableHeight, xStart, yStart;

      if (videoAspectRatio > canvasAspectRatio) {
        renderableWidth = videoHeight * canvasAspectRatio;
        renderableHeight = videoHeight;
      } else {
        renderableWidth = videoWidth;
        renderableHeight = videoWidth / canvasAspectRatio;
      }
      
      // Save the current context state
      ctx.save();
      if (this.mirrorImage) {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }
      
      xStart = (videoWidth - renderableWidth) / 2;
      yStart = (videoHeight - renderableHeight) / 2;

      // Translate and rotate the canvas context based on the camera angle
      ctx.filter = `blur(${this.blur.current}px)`;
      switch (this.videoFeedRotation) {
        case 90:
          ctx.translate(canvas.width/2,canvas.height/2);
          ctx.rotate(Math.PI / 2);
          ctx.drawImage(video, -canvas.height/2, -canvas.width/2, canvas.height, canvas.width)
          break;
        // case 180:
        //   break;
        case 270:
          ctx.translate(canvas.width/2,canvas.height/2);
          ctx.rotate(-Math.PI / 2);
          ctx.drawImage(video, -canvas.height/2, -canvas.width/2, canvas.height, canvas.width)
          break;
        default:
          // No rotation needed for 0 degrees
          ctx.drawImage(video, xStart, yStart, renderableWidth, renderableHeight, 0, 0, canvas.width, canvas.height);
          break;
      }      
      
      ctx.restore();

      ctx.filter = `blur(${this.blur.current}px)`;

      // Stickers
      if (this.stickers.enabled) {
        // sort this.stickers.list by item.y
        let listaOrdenadaPorY = this.stickers.list
        listaOrdenadaPorY.sort((a, b) => (a.y > b.y) ? 1 : -1)
        listaOrdenadaPorY.forEach((item) => {
          if (item.enabled) {
            ctx.save();

            let sticker = this.$refs["sticker" + item.id][0]

            // mapeo width según posicion en y
            let w = utils.map(item.y, 0, 1, this.stickers.stickerMinWidth, this.stickers.stickerMaxWidth);
            // defino dos widths porque le cambio el tamaño segun la posicion en x
            // para dar sensacion de profundidad
            let h

            if (item.mediaType === 'video') { 
              h = (sticker.videoHeight / sticker.videoWidth) * w;
            } else if(item.c === 'image'){
              h = (sticker.naturalHeight / sticker.naturalWidth) * w;
            }
            
            w *= item.scale;
            h *= item.scale;
            item.animx = utils.lerp(item.animx, item.x, 0.1)
            item.animy = utils.lerp(item.animy, item.y, 0.1)
            const newPos = this.StickerPositionRemap(item.animx, item.animy)
            let x = (newPos.x * (canvas.width-w));
            let y = (newPos.y * (canvas.height-h));

            if(item.mediaType === 'video') {
              sticker.play()
            }
            // Maxima rotacion desde campo de payload
            let maxRotation = item.rotation ? item.rotation : 0;
            // Rotacion segun posicion en x
            let rotation = utils.map(item.x, 0, 1, -maxRotation, maxRotation);

            ctx.translate(x + w / 2, y + h / 2);
            ctx.rotate(rotation * Math.PI / 180);
            ctx.drawImage(sticker, -w / 2, -h / 2, w, h);
            // ctx.drawImage(sticker, x, y, w, h);
            ctx.restore();
          }
        })
      }
      
      if(this.options.blurToFrame) {
        ctx.filter = `blur(${this.blur.current}px)`;  
      } else {
        ctx.filter = 'blur(0px)';  
      }
      
      // Picture Frame
      if (this.pictureFrame) {
        let pictureFrame = this.$refs.pictureFrame
        let frameWidth = canvas.width;
        // proportionate height
        let frameHeight = (pictureFrame.height / pictureFrame.width) * frameWidth;
        let frameX = 0; //(canvas.width - frameWidth) / 2;
        let frameY = 0; //(canvas.height - frameHeight) / 2;
        ctx.drawImage(pictureFrame, frameX, frameY, frameWidth, frameHeight);
      }

      if(this.options.videoFrame){
        let videoFrame = this.$refs.videoFrame
        videoFrame.play()
        // proportionate height
        // Get video width and height
        let frameWidth = canvas.width;
        let frameHeight = (videoFrame.videoHeight / videoFrame.videoWidth) * frameWidth;
        let frameX = (canvas.width - frameWidth) / 2;
        let frameY = (canvas.height - frameHeight) / 2;
        ctx.drawImage(videoFrame, frameX, frameY, frameWidth, frameHeight);
      }

      // Time percent
      if (this.gameSubState == this.SUBSTATES.capturing) {
        this.recordingTimePercent = (Date.now() - this.countdown.timestampStarted) / (this.clipDuration * 1000) * 100
        if (this.recordingTimePercent >= 100) {
          this.mediaRecorder.stop()
        }
        // Convert percent to remaining time in seconds
        let timeLeft = this.clipDuration - (this.recordingTimePercent / 100 * this.clipDuration);
        if(timeLeft <= 0){
          this.recordingTimeLeft = "00:00"
        }else{
          // Format timeLeft as mm:ss
          let minutes = Math.floor(timeLeft / 60);
          let seconds = Math.floor(timeLeft % 60);
          this.recordingTimeLeft = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
      }

      // CTA Timer
      if(this.gameSubState == this.SUBSTATES.cta){
        this.ctaTimer.elapsed += 1/60
        if(this.ctaTimer.elapsed % this.ctaTimer.freqMoverPjs <= 0.02){
          this.MoveActiveStickers()
        }
        switch(this.ctaState){
          case 0:
            if(this.ctaTimer.elapsed >= this.ctaTimer.timePersonajes){
              this.ctaTimer.elapsed = 0;
              this.ctaState = 1; 
            }
            break;
          case 1:
              if(this.ctaTimer.elapsed >= this.ctaTimer.timeBlur){
                this.ctaTimer.elapsed = 0;
                // this.ctaState = 0;// Deshabilito temporalmente que se cambien los subestados
              }
              break;
        }
      }

      requestAnimationFrame(this.draw.bind(this));

    },
    async startWebcam() {
      console.log("Starting webcam", this.constraints)
      navigator.mediaDevices.getUserMedia(this.constraints)
        .then((stream) => {

          let settings = stream.getTracks()[0].getSettings()
          console.log("Resolucion camara:", settings.width, "x", settings.height)

          this.$refs.photoboothVideo.srcObject = stream;
          this.$refs.photoboothVideo.play();
          this.webcamStarted = true;
        })
        .catch((err) => {
          console.error(`An error occurred: ${err}`);
        });
    },
    changeDevice() {
      console.log("Switching to device", this.currentDevice?.label, this.currentDevice.deviceId)
      localStorage.setItem("photoboothDeviceId", this.currentDevice.deviceId)
      this.constraints.video.deviceId = { exact: this.currentDevice.deviceId }
      this.startWebcam()
    },
    dataURLtoBlob(dataURL) {
      let array, binary, i, len;
      binary = atob(dataURL.split(',')[1]);
      array = [];
      i = 0;
      len = binary.length;
      while (i < len) {
        array.push(binary.charCodeAt(i));
        i++;
      }
      return new Blob([new Uint8Array(array)], {
        type: 'image/png'
      });
    },
    async CapturePhotoWithCountdown() {
      this.showCapturedImages = false;
      await this.StartCountDown();
      this.CapturePhoto()
    },
    async StartCountDown() {
      return new Promise((resolve) => {
        this.gameSubState = this.SUBSTATES.startingCapture;
        this.recordingTimePercent = 0;
        this.$socket.client.emit("roomPlayersMessage", { type: "countdownStarted" })
        this.countdown.number = 3
        setTimeout(() => {
          this.countdown.number = 2
        }, 1000);

        setTimeout(() => {
          this.countdown.number = 1
        }, 2000);

        this.countdown.timeout = setTimeout(() => {
          this.gameSubState = this.SUBSTATES.capturing
          this.countdown.timestampStarted = Date.now()
          resolve()
        }, 3000);
      })
    },
    async CapturePhoto() {
      this.$socket.client.emit("roomPlayersMessage", { type: "photoTaken" })
      let disparosPorToma = Math.max(1, this.cantDisparosPorToma); // minimo 1
      console.log("Capture photo", this.currDisparo + 1, "/", this.cantDisparosPorToma)

      this.Flash()

      this.$store.commit("StartLoading")

      const file = this.dataURLtoBlob(this.$refs.photoboothCanvas.toDataURL());
      const formData = new FormData;
      let d = new Date()
      let filename = 'photobooth_' + ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + "_" + ("0" + d.getHours()).slice(-2) + "-" + ("0" + d.getMinutes()).slice(-2) + "-" + ("0" + (d.getSeconds() + 1)).slice(-2) + ".png";

      formData.append('file', file, filename);
      formData.append('space', this.$store.getters['space/config'].space.id);
      formData.append('experience', this.$store.getters['space/config'].experience.id);

      // Hay que hacerlo asi, no se puede asignar directamente el array
      // TODO implementar options.saveMediaOnlyToUserWhoPressed
      // Si fuese verdadero en el siguiente formData solo hay que agregar al usuario que presiono el boton
      console.log("usersCapturing", this.usersCapturing)
      this.usersCapturing.forEach(user => {
        formData.append('users', user.dbid);
      });

      // Post the form, just make sure to set the 'Content-Type' header
      const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/userimages", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      this.$store.commit("StopLoading")

      // console.log("res", res)
      if (this.currDisparo == 0) {
        this.capturedImages = []
      }
      this.capturedImages.push(res.data.doc);
      this.currDisparo++;
      if (this.currDisparo < disparosPorToma) {
        this.CaptureTimeout = setTimeout(() => {
          this.CapturePhoto()
        }, this.delayEntreDisparos * 1000);
      } else {
        this.currDisparo = 0;
        this.showCapturedImages = true;
        // Les envio a los jugadores
        const capturedImages = this.capturedImages.map(x => ({ large: x.url, mobile: x.sizes.mobile.url, id: x.id }))
        this.$socket.client.emit("roomPlayersMessage", { type: "capturedImages", capturedImages })
        this.gameSubState = this.SUBSTATES.sharing;
      }
    },
    async CaptureVideo() {
      console.log("Capture video started")
      let canvas = this.$refs.photoboothCanvas;
      const canvasStream = canvas.captureStream(this.clipFps); // 1 FPS
      // Create a MediaRecorder to record the stream
      // Get the media stream from the audio element
      const audioStream = this.$refs.musicRecording.captureStream();
      
      // Merge both streams into a single stream
      const mergedStream = new MediaStream([...canvasStream.getTracks(), ...audioStream.getTracks()]);
      this.mediaRecorder = new MediaRecorder(mergedStream, { mimeType: 'video/webm' });



      let chunks = [];

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("Capture video data");
          chunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = async () => {
        // When recording is stopped, create a Blob from the collected chunks
        // this.$sfxStop("musicRecording")
        this.$refs.musicRecording.pause()
        if (this.gameSubState == this.SUBSTATES.cta) {
          // Si el usuario se fue antes de terminar de grabar
          return
        }
        // this.$socket.client.emit("roomPlayersMessage", { type: "convertingUploading" })
        // const videoBlob = new Blob(chunks, { type: 'video/webm' });
        // this.recording = false;
        // this.$store.commit("StartLoading")
        
        this.$socket.client.emit("roomPlayersMessage", { type: "convertingUploading" })
        console.log("Capture video stopped")
        
        if (chunks.length > 0) {
          if(this.webmBlob){
            this.webmBlob = null;
          }
          this.webmBlob = new Blob(chunks, { type: 'video/webm' });
          chunks = []; // Clear the chunks array
        }
        // console.log("** this.webmBlob", this.webmBlob)
        this.recording = false;
        // this.$store.commit("StartLoading")

        // if(location.href.includes("localhost") || location.href.includes("192.168")){
        // Local: Sin conversion. queda webm
        if(this.capturedVideoSrc){
          URL.revokeObjectURL(this.capturedVideoSrc)
        }
        this.capturedVideoSrc = URL.createObjectURL(this.webmBlob);
        
        this.showCapturedVideo = true;

        this.$socket.client.emit("roomPlayersMessage", { type: "userDecideRedo" })
        this.gameSubState = this.SUBSTATES.sharing;
        this.showCapturedVideoTimeout = setTimeout(async () => {
          this.SaveVideo()
        }, this.clipDuration * 2 * 1000);

        // Clean up media streams
        canvasStream.getTracks().forEach(track => track.stop());
        audioStream.getTracks().forEach(track => track.stop());

        // setTimeout(() => {
        //   // Clear blob references
        //   this.webmBlob = null;
        // }, 2000);
      };

      this.mediaRecorder.onerror = function (error) {
        console.error("MediaRecorder error:", error);
      };

      this.mediaRecorder.start();
      this.recording = true;

      // Properly handle stopping the media streams
      const stopStreams = () => {
        canvasStream.getTracks().forEach(track => track.stop());
        audioStream.getTracks().forEach(track => track.stop());
      };

      // Handle the stopping of media streams when the component is destroyed
      this.$on('hook:beforeDestroy', stopStreams);
    },
    async UploadVideo(blob, extension = "mp4") {
      console.log("Upload video")
      const formData = new FormData;
      let d = new Date()
      let filename = 'photobooth_' + ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + "_" + ("0" + d.getHours()).slice(-2) + "-" + ("0" + d.getMinutes()).slice(-2) + "-" + ("0" + (d.getSeconds() + 1)).slice(-2) + "." + extension;

      // Convert to mp4
      formData.append('file', blob, filename);
      formData.append('space', this.$store.getters['space/config'].space.id);
      formData.append('experience', this.$store.getters['space/config'].experience.id);

      // Hay que hacerlo asi, no se puede asignar directamente el array
      // TODO implementar options.saveMediaOnlyToUserWhoPressed
      // Si fuese verdadero en el siguiente formData solo hay que agregar al usuario que presiono el boton
      this.usersCapturing.forEach(user => {
        formData.append('users', user.dbid);
      });

      // Post the form, just make sure to set the 'Content-Type' header
      const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/uservideos", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // Envio socket a los jugadores con URL video
      this.$socket.client.emit("roomPlayersMessage", { type: "capturedVideo", video: res.data.doc })
      this.capturedVideoId = res.data.doc.id
      this.gameSubState = this.SUBSTATES.sharing;
      console.log("res", res)
      this.$store.commit("StopLoading")

      this.showCapturedVideo = false;
      this.gameSubState = this.SUBSTATES.cta;
      // Timeout para dejar de mostrar el video grabado. Misma duracion del video
      // this.showCapturedVideoTimeout = setTimeout(async () => {
      //   if(this.gameSubState != this.SUBSTATES.sharing){ return } // Solo aplica si todavia estoy en sharing
      //   this.$socket.client.emit("kickPlayersToMenu");
      //   // Convert and upload video
      //   // Conversion video local
      //   this.$store.commit("StartLoading")
      //   const mp4Blob = await this.convertWebMtoMP4(videoBlob)
      //   const videoURL = URL.createObjectURL(mp4Blob);
      //   console.log("** mp4blob", videoURL)
      //   this.capturedVideoSrc = videoURL;
      //   this.showCapturedVideo = true;
      //   this.UploadVideo(mp4Blob)
      //   this.$store.commit("StopLoading")

      //   this.showCapturedVideo = false;
      //   this.gameSubState = this.SUBSTATES.cta;
      // }, this.clipDuration * 2 * 1000);
    },
    async getMaxVideoResolution() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: this.currentDevice.deviceId } } });
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();

        if ('width' in capabilities && 'height' in capabilities) {
          const maxWidth = capabilities.width.max;
          const maxHeight = capabilities.height.max;
          return { maxWidth, maxHeight };
        } else {
          return 'Resolution information not available';
        }
      } catch (error) {
        return 'Error accessing the webcam';
      }
    },
    // async convertWebMtoMP4() {
    //   const inputFileName = 'input.webm'; // Input file name
    //   const outputFileName = 'output.mp4'; // Output file name

    //   this.ffmpeg.FS('writeFile', inputFileName, await fetchFile(this.webmBlob));

    //   this.ffmpegProcess = await this.ffmpeg.run(
    //     '-i', inputFileName, // Input video file
    //     '-c:v', 'h264', // H.264 video codec
    //     '-c:a', 'aac', // AAC audio codec
    //     outputFileName
    //   );

    //   const mp4Data = this.ffmpeg.FS('readFile', outputFileName);

    //   this.ffmpeg.FS('unlink', inputFileName);
    //   this.ffmpeg.FS('unlink', outputFileName);

    //   return new Blob([mp4Data.buffer], { type: 'video/mp4' });
    // },
    Flash() {
      this.showFlash = true;
      setTimeout(() => {
        this.showFlash = false;
      }, 500);
    }
  },
  watch: {
    ctaState(val){
      if(val == 0){
        // Cuando se activa el ctaState 1, cambio los stickers
        this.ChangeActiveStickers()
      }
    },
    gameSubState(val){
      switch(val){
        case this.SUBSTATES.cta:
          this.ctaState = 1;
          this.ctaTimer.elapsed = 0;
          this.usersCapturing = [];

          this.recording = false;
          this.SetScreenPermissionToRestart(true);
          this.ChangeActiveStickers();
          break;
        
        case this.SUBSTATES.creating:
          this.UpdateStickerPostion()
          break;
        // case this.SUBSTATES.creating:
        //   this.$sfxPlay('musicCreation')
        //   break;
        // case this.SUBSTATES.startingCapture:
        //   this.$sfxPlay('musicRecording')
        //   break;
        // case this.SUBSTATES.capturing:
        //   this.$sfxPlay('musicRecording')
        //   break;
        // case this.SUBSTATES.sharing:
        //   this.$sfxStop('musicRecording')
        //   this.$sfxStop('musicCreation')
        //   break;
      }
    },
    currentDeviceId() {
      this.changeDevice()
    },
    players(val, prevVal) {
      if(val == 0 && this.gameSubState == this.SUBSTATES.creating){
        // Si se va un jugador y estabamos creating, volvemos a cta
        this.gameSubState = this.SUBSTATES.cta
        this.$sfxStop('musicCreation')
      }
      // players changed
      console.log("players changed", val);
      if (val.length == 1 && prevVal.length == 0) {
        // El primero que se une
        this.gameSubState = this.SUBSTATES.creating
        this.usersCapturing = JSON.parse(JSON.stringify(this.players)) // copia sin referencia
        this.$sfxPlay('musicCreation')
      }
    },
    serverState(val, oldVal) {
      console.log("serverState changed", val, oldVal);
    }
  },
  beforeMount() {
    document.documentElement.style.setProperty("--canvasWidth", `${this.canvasWidth}px`)
    document.documentElement.style.setProperty("--canvasHeight", `${this.canvasHeight}px`)
  },
  async mounted() {
    console.log("📷 Phtobooth mounted")

    // Load music
    this.$sfxLoad({
      musicRecording: {url: this.musicRecording},
      musicCreation: {url: this.musicCreation}
    })
    // Load stickers
    if (this.stickers.enabled && this.stickers.list.length > 0) {
      this.stickers.list.forEach((item) => {
        item.x = Math.random();
        item.y = Math.random();
        item.animx = item.x
        item.animy = item.y
        item.enabled = false;
      })
    }
    this.ChangeActiveStickers()

    this.canvasContext = this.$refs.photoboothCanvas.getContext('2d');
    this.devices = (await navigator.mediaDevices.enumerateDevices()).filter(x => x.kind == "videoinput");
    let preferedDeviceId = localStorage.getItem("photoboothDeviceId")
    if (preferedDeviceId != null) {
      setTimeout(() => {
        this.constraints.video.deviceId = { exact: preferedDeviceId }
        this.currentDeviceId = preferedDeviceId
      }, 1000);
    } else {
      console.log("///")
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      this.currentDeviceId = this.devices[0].deviceId
      this.startWebcam()
    }
    // Load ffmpeg

    try{
      this.$store.commit("StartLoading")
      // this.ffmpeg = createFFmpeg({
      //   log: true,
      //   corePath: '/ffmpeg/ffmpeg-core.js',
      //   wasmPath: '/ffmpeg/ffmpeg-core.wasm',
      // });
      // await this.ffmpeg.load();
      VideoConversionQueue.Init()
      this.$store.commit("StopLoading")
    }catch(e){
      console.error("Error loading ffmpeg", e)
      this.$store.commit("StopLoading")
    }
    
    this.SetScreenPermissionToRestart(true);
    this.draw()
  },
  beforeDestroy() {
    if(this.capturedVideoSrc){
      URL.revokeObjectURL(this.capturedVideoSrc)
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
// @import '@/styles/mixins.scss';

canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#photobooth {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: grey;
  z-index: 99;
}

#photoboothVideo {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  background: #000;

  &.mirror {
    transform: scale(-1, 1);
  }
}

.videoContainer {
  position: relative;
  width: var(--canvasWidth);
  height:  var(--canvasHeight);

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 12px red;
    opacity: 0;
    transition: all 100ms;
  }

  &.recording::after {
    opacity: 1;
  }

  #countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 47px;
    width: 85px;
    height: 85px;
    display: flex;
    border: solid 13px white;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
  }

  #blurBackground {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // backdrop-filter: blur(10px);
    transition: all 0.5s ease-in-out;

    &.active {
      backdrop-filter: blur(5px) brightness(0.75);
    }
  }

  #capturedImage {

    #imagesContainer {
      position: absolute;
      z-index: 20;
      top: 50%;
      left: 50%;
      scale: .8;
      transform: rotate(-5deg) translate(-50%, -50%); // TODO random rotation
      display: flex;
      background: black;

      div {
        margin: -1px;
      }

      img {
        border: 20px solid #fff;
      }
    }
  }
}

#videoOptions {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999999;

  &>* {
    margin: 5px;
  }
}

#capturedVideo {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  display: flex;
  flex-direction: column;

  #capturedVideoCta{
    position: relative;
    top: -36px;
    display: flex;
    align-items: center;
    span{
      margin: 0 10px;
    }
  }

  video {
    width: 50vw;
    border-style: solid;
    border-color: white;
    border-width: 10px 10px 40px 10px;
  }
}

#videoRecordingIndicator {
  position: absolute;
  top: 40px;
  right: 50px;
  height: 80px;
  padding: 10px 40px;
  gap: 20px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: $primary;
  border-radius: 40px;
  font-size: 1.7em;
  font-weight: bold;

  .time {
    width: 2.5em;
    text-align: center;
  }
}

#activePlayer{
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0px);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 30px 10px 10px;
  border-radius: 40px;
  font-size: 1.7em;
  color: black;
  font-weight: bold;
  border: 3px solid #0c9dff;
}

#flash {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
}

.qrOverlay{
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  transition: all 0.5s;
  opacity: 1;

  &.hidden{
    opacity: 0;
  }
}
</style>

<!-- noscope -->
<style lang="scss">
#activePlayer{
  color: #fff;
  .playerAvatarContainer{
    margin-right: 5px;
  }
  .playerAvatar {
    margin-right: 10px;
  }
}

.loading-screen {
  width: var(--canvasWidth);
  height:  var(--canvasHeight);
}

</style>